
import { Tabs } from "antd";
import { has, extend } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";
import { ContactGegevens } from "./ContactGegevens";
import { Fokker } from "./Fokker";
import { Lidmaatschap } from "./Lidmaatschap";
import { Logboek } from "./Logboek";
import { Web } from "./Web";

const TabPane = Tabs.TabPane;

interface DetailsProps extends RouteComponentProps<any> {
    member: any;
    memberLoaded: boolean;
    loadMembers: () => void;

    goToMemberList: () => void;
}

class DetailsComponent extends React.Component<DetailsProps> {

    componentDidMount() {
        if (!this.props.memberLoaded) {
            this.props.loadMembers();
        }
    }

    render() {
        if (this.props.memberLoaded) {
            return (
                <div>
                    <h2>Ledenbeheer</h2>
                    <Tabs>
                        <TabPane tab="Contactgegevens" key="contact">
                            <ContactGegevens member={this.props.member} goToMemberList={this.props.goToMemberList} />
                        </TabPane>
                        <TabPane tab="Lidmaatschap" key="lidmaatschap">
                            <Lidmaatschap mid={this.props.member.id} />
                        </TabPane>
                        <TabPane tab="Fokker" key="fokker">
                            <Fokker mid={this.props.member.id} />
                        </TabPane>
                        <TabPane tab="Web" key="web">
                            <Web mid={this.props.member.id} />
                        </TabPane>
                        <TabPane tab="Logboek" key="logboek">
                            <Logboek mid={this.props.member.id} />
                        </TabPane>
                    </Tabs>
                </div>
            );
        } else {
            return (
                <div>
                    <h2>Ledenbeheer</h2>
                    <p>De gegevens worden geladen...</p>
                </div>
            );
        }
    }
}

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return extend(
        {},
        ownProps,
        {
            member: state.member.members[ownProps.match.params.mid],
            memberLoaded: has(state.member.members, ownProps.match.params.mid)
        }
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadMembers: () => {
            dispatch(memberActions.findAll());
        }
    };
};

export const Details = connect(mapStateToProps, mapDispatchToProps)(DetailsComponent);

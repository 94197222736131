import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { loadUser, OidcProvider } from "redux-oidc";
import { App } from "./components/App";
import { AppCallback } from "./components/AppCallback";
import { Login } from "./components/Login";
import { store } from "./store";
import { userManager } from "./userManager";

loadUser(store, userManager);

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <OidcProvider userManager={userManager} store={store}>
            <Router history={history}>
                <Switch>
                    <Route path="/callback" render={props => <AppCallback {...props} userManager={userManager} />} />
                    <Route path="/login" component={Login} />
                    <Route path="/" component={App} />
                </Switch>
            </Router>
        </OidcProvider>
    </Provider>,
    document.getElementById("app")
);


import { Descriptions, List } from "antd";
import { join, map, orderBy } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";

function formatType(data: any): string {
	const telling = data.typeTelling === "exact" ? "Telling" : data.typeTelling === "schatting" ? "Schatting" : "<fout>";
	let aantallen: string = "<fout>";
	if (data.typeAantal === "detail") {
		aantallen = "jonge en volwassen dieren per geslacht";
	} else if (data.typeAantal === "globaal") {
		aantallen = "het globaal aantal dieren";
	} else if (data.typeAantal === "jong-oud") {
		aantallen = "het aantal jonge en volwassen dieren";
	} else if (data.typeAantal === "man-vrouw") {
		aantallen = "het aantal dieren per geslact";
	}

	return join([telling, aantallen], " van ");
}

function getAantallen(data: any) {
	if (data.typeAantal === "detail") {
		return [
			{ label: "Mannelijk volwassen", value: data.aantalManVolwassen },
			{ label: "Mannelijke jong", value: data.aantalManJong },
			{ label: "Vrouwelijk volwassen", value: data.aantalVrouwVolwassen },
			{ label: "Vrouwelijk jong", value: data.aantalVrouwJong }
		];
	} else if (data.typeAantal === "globaal") {
		return [{ label: "Globaal aantal", value: data.aantalGlobaal }];
	} else if (data.typeAantal === "jong-oud") {
		return [
			{ label: "Volwassen", value: data.aantalVolwassen },
			{ label: "Jong", value: data.aantalJong }
		];
	} else if (data.typeAantal === "man-vrouw") {
		return [
			{ label: "Mannelijk", value: data.aantalMan },
			{ label: "Vrouwelijk", value: data.aantalVrouw }
		];
	}
}

export const LaatsteInvoer = () => {

	const dispatch = useDispatch();

	const { laatsteInvoer } = useSelector((store: StoreState) => ({
		laatsteInvoer: store.mijn.laatsteVereenvoudigdBeheerInvoer
	}));

	useEffect(
		() => {
			dispatch(mijnActions.getLaatsteVereenvoudigdBeheerInvoer());
		},
		[false]
	);

	function getData() {
		const result = map(laatsteInvoer, i => ({
			ras: i.ras,
			kleur: i.kleur || "",
			laatsteInvoer: moment(i.tijdstip).format("DD/MM/YYYY"),
			type: formatType(i),
			aantallen: getAantallen(i)
		}));

		return orderBy(result, "ras", "asc");
	}

	return (
		<Fragment>
			<List
				itemLayout="horizontal"
				dataSource={getData()}
				renderItem={item => (
					<List.Item key={item.ras}>
						<Descriptions title={item.ras}>
							<Descriptions.Item key="kleur" label="Kleur">{item.kleur}</Descriptions.Item>
							<Descriptions.Item key="laatsteInvoer" label="Laatste invoer">{item.laatsteInvoer}</Descriptions.Item>
							<Descriptions.Item key="type" label="Type">{item.type}</Descriptions.Item>
							{map(item.aantallen, aantal => (
								<Descriptions.Item key={aantal.label} label={aantal.label}>{aantal.value}</Descriptions.Item>
							))}
						</Descriptions>
					</List.Item>
				)}
			/>
		</Fragment>
	);
};

import axios from "axios";
import { config } from "../config";

export interface Report {
    data: string;
    mime: string;
    filename: string;
}

export async function getReportVerzendlijst(accessToken: string): Promise<Report> {
    const response = await axios.get(
        config.apiUri + "/reports/verzendlijst",
        { headers: { authorization: "Bearer " + accessToken } }
    );

    return downloadFile(response.data.url, "verzendlijst.xlsx");
}

export async function getReportBetalingsUitnodiging(accessToken: string, data: { kwartaal: string, statusBijwerken: boolean }): Promise<Report> {
    const response = await axios.get(
        config.apiUri + "/reports/betalingsuitnodiging",
        {
            params: data,
            headers: { authorization: "Bearer " + accessToken }
        }
    );

    return downloadFile(response.data.url, "betalingsuitnodiging.xlsx");
}

export async function getReportMembersVsWebUsers(accessToken: string): Promise<Report> {
    const response = await axios.get(
        config.apiUri + "/reports/members-webusers",
        { headers: { authorization: "Bearer " + accessToken } }
    );

    return downloadFile(response.data.url, "leden-webgebruikers.xlsx");
}

export async function getReportMailingLists(accessToken: string): Promise<Report> {
    const response = await axios.get(
        config.apiUri + "/reports/mailinglists",
        { headers: { authorization: "Bearer " + accessToken } }
    );

    return downloadFile(response.data.url, "mailinglists.csv");
}

export async function downloadFile(url: string, filename: string): Promise<Report> {
    const response = await axios.get(url, { responseType: "blob" });
    return { data: response.data, mime: response.headers["content-type"], filename: filename };
}

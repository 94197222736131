import { produce } from "immer";
import { extend, isUndefined } from "lodash";
import { AnyAction } from "redux";
import { createAction } from "redux-actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ERROR_ACTION, WAIT_FOR_ACTION } from "redux-wait-for-action";
import { StoreState } from ".";
import * as api from "../api/mijn";
import { createMemberData, createSubscriptionPayment, getBreederData, getMemberData, getSubscriptionData, getSubscriptionPayment } from "../api/mijn";
import { InvoerData } from "../components/my/vereenvoudigd-beheer/interfaces";
import { config } from "../config";
import { completeAsyncUiAction, startAsyncUiAction } from "./ui";

export interface MijnVereenvoudigdBeheer {
	deelnemer: boolean;
	akkoordPubliekContact: boolean;
}

export interface MijnState {
	member: any;
	subscription: any;
	breeder: any;
	payment: any;
	vereenvoudigdBeheer?: MijnVereenvoudigdBeheer;
	vereenvoudigdBeheerData: InvoerData[];
	laatsteVereenvoudigdBeheerInvoer: any[];
}

const initialState: MijnState = {
	member: undefined,
	subscription: undefined,
	breeder: undefined,
	payment: undefined,
	vereenvoudigdBeheer: undefined,
	vereenvoudigdBeheerData: [],
	laatsteVereenvoudigdBeheerInvoer: []
};

export const mijnActions = {
	getContactGegevens: createAction("mijn/contactgegevens/get"),
	getContactGegevensOk: createAction("mijn/contactgegevens/get/ok"),
	updateContactGegevens: createAction("mijn/contactgegevens/update"),
	getLidmaatschap: createAction("mijn/lidmaatschap/get"),
	getLidmaatschapOk: createAction("mijn/lidmaatschap/get/ok"),
	loadBreeder: (): AnyAction => {
		return { type: "MY_BREEDER_LOAD", [WAIT_FOR_ACTION]: "MY_BREEDER_LOADED" };
	},
	initieerBetalingLidgeld: createAction("mijn/betaling-lidgeld/init", (bedrag: number) => ({ amount: bedrag, redirectUri: config.subscriptionPaymentRedirect })),
	loadSubscriptionPayment: (pid: string): AnyAction => {
		return {
			type: "MY_SUBSCRIPTION_PAYMENT_LOAD",
			[WAIT_FOR_ACTION]: "MY_SUBSCRIPTION_PAYMENT_LOADED",
			[ERROR_ACTION]: "MY_SUBSCRIPTION_PAYMENT_LOAD_ERROR",
			payload: pid
		};
	},
	getDataVereenvoudigdBeheer: (uiAction?: string): AnyAction => {
		return {
			type: "MY_VEREENVOUDIGD_BEHEER_LOAD",
			uiAction: uiAction,
			[WAIT_FOR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_LOADED",
			[ERROR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_LOAD_ERROR"
		};
	},
	deelnemenVereenvoudigdBeheer: (): AnyAction => {
		return {
			type: "MY_VEREENVOUDIGD_BEHEER_DEELNEMEN",
			[WAIT_FOR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_DEELNEMEN_OK",
			[ERROR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_DEELNEMEN_ERROR"
		};
	},
	loadGegevensVereenvoudigdBeheerData: (): AnyAction => {
		return {
			type: "MY_VEREENVOUDIGD_BEHEER_DATA_LOAD",
			[WAIT_FOR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_DATA_LOADED",
			[ERROR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_DATA_LOAD_ERROR"
		};
	},
	getLaatsteVereenvoudigdBeheerInvoer: createAction("mijn/vereenvoudigd-beheer/data/laatste"),
	getLaatsteVereenvoudigdBeheerInvoerOk: createAction("mijn/vereenvoudigd-beheer/data/laatste/ok"),
	verstuurGegevensVereenvoudigdBeheer: (data: InvoerData): AnyAction => {
		return {
			type: "MY_VEREENVOUDIGD_BEHEER_DATA_SEND",
			[WAIT_FOR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_DATA_SENT",
			[ERROR_ACTION]: "MY_VEREENVOUDIGD_BEHEER_DATA_ERROR",
			payload: data
		};
	},
	saveVereenvoudigdBeheerInvoer: createAction("mijn/vereenvoudigd-beheer/invoer")
};

export function mijnReducer(state: MijnState = initialState, action: AnyAction): MijnState {
	if (!action) {
		return extend({}, state);
	}

	switch (action.type) {
		case mijnActions.getContactGegevensOk.toString():
			return produce(state, draft => {
				draft.member = action.payload || {};
			});
		case mijnActions.getLidmaatschapOk.toString():
			return produce(state, draft => {
				draft.subscription = action.payload || {};
			});
		case "MY_SUBSCRIPTION_PAYMENT_LOADED":
			return extend({}, state, { payment: action.payload });
		case "MY_BREEDER_LOADED":
			return extend({}, state, { breeder: action.payload || {} });
		case "MY_VEREENVOUDIGD_BEHEER_LOADED":
			return extend({}, state, { vereenvoudigdBeheer: action.payload });
		case "MY_VEREENVOUDIGD_BEHEER_DATA_LOADED":
			return extend({}, state, { vereenvoudigdBeheerData: action.payload });
		case mijnActions.getLaatsteVereenvoudigdBeheerInvoerOk.toString():
			return produce(state, draft => {
				draft.laatsteVereenvoudigdBeheerInvoer = action.payload;
			});
		default:
			return extend({}, state);
	}
}

export function* mijnSaga() {
	yield takeLatest(mijnActions.getContactGegevens.toString(), getContactGegevensSaga);
	yield takeLatest(mijnActions.updateContactGegevens.toString(), updateContactGegevensSaga);
	yield takeLatest(mijnActions.getLidmaatschap.toString(), getLidmaatschapSaga);
	yield takeLatest("MY_BREEDER_LOAD", loadMyBreederSaga);
	yield takeLatest("MY_MEMBER_CREATE", createMyMemberSaga);
	yield takeLatest(mijnActions.initieerBetalingLidgeld.toString(), initieerBetalingLidgeldSaga);
	yield takeLatest("MY_SUBSCRIPTION_PAYMENT_LOAD", loadSusbcriptionPaymentSaga);
	yield takeLatest("MY_VEREENVOUDIGD_BEHEER_LOAD", loadVereenvoudigdBeheerDataSaga);
	yield takeLatest("MY_VEREENVOUDIGD_BEHEER_DEELNEMEN_OK", updatedVereenvoudigdBeheerDataSaga);
	yield takeLatest("MY_VEREENVOUDIGD_BEHEER_DEELNEMEN", vereenvoudigdBeheerDeelnemenSaga);
	yield takeLatest("MY_VEREENVOUDIGD_BEHEER_DATA_LOAD", vereenvoudigdBeheerLoadDataSaga);
	yield takeLatest(mijnActions.getLaatsteVereenvoudigdBeheerInvoer.toString(), getLaatsteVereenvoudigdBeheerInvoerSaga);
	yield takeLatest("MY_VEREENVOUDIGD_BEHEER_DATA_SEND", vereenvoudigdBeheerSendDataSaga);
	yield takeLatest(mijnActions.saveVereenvoudigdBeheerInvoer.toString(), saveVereenvoudigdBeheerInvoerSaga);
}

function* getContactGegevensSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(getMemberData, accessToken);
		// // omwille van select
		if (result) {
			let new_val = undefined;
			switch (result.akkoord_adres_doorgeven) {
				case 1:
					new_val = 'Ja';
					break;
				case 0:
					new_val = 'Nee';
					break;
			}

			result.akkoord_adres_doorgeven = new_val;
		}

		yield put(mijnActions.getContactGegevensOk(result));
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* updateContactGegevensSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(api.updateContactGegevens, accessToken, action.payload);
		yield put(mijnActions.getContactGegevens());
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* createMyMemberSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		const result = yield call(createMemberData, accessToken, action.payload);
		yield put({ type: "MY_MEMBER_CREATED", payload: result });
		yield put(mijnActions.getContactGegevens());
	} catch (err) {
		yield put({ type: "MY_MEMBER_CREATE_ERROR", payload: err });
	}
}

function* getLidmaatschapSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(getSubscriptionData, accessToken);
		yield put(mijnActions.getLidmaatschapOk(result));
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* loadMyBreederSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		const result = yield call(getBreederData, accessToken);
		yield put({ type: "MY_BREEDER_LOADED", payload: result });
	} catch (err) {
		yield put({ type: "MY_BREEDER_LOADED", payload: {} });
	}
}

function* initieerBetalingLidgeldSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(createSubscriptionPayment, accessToken, action.payload);
		yield completeAsyncUiAction(action.type, null, result);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* loadSusbcriptionPaymentSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		const result = yield call(getSubscriptionPayment, accessToken, action.payload);
		yield put({ type: "MY_SUBSCRIPTION_PAYMENT_LOADED", payload: result });
	} catch (err) {
		yield put({ type: "MY_SUBSCRIPTION_PAYMENT_LOAD_ERROR" });
	}
}

function* loadVereenvoudigdBeheerDataSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const data = yield call(api.getVereenvoudigdBeeerData, accessToken);
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_LOADED", payload: data });
		yield completeAsyncUiAction(action);
	} catch (err) {
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_LOAD_ERROR", error: err });
		yield completeAsyncUiAction(action, err);
	}
}

function* updatedVereenvoudigdBeheerDataSaga(action: AnyAction): any {
	yield put({ type: "MY_VEREENVOUDIGD_BEHEER_LOAD" });
}

function* vereenvoudigdBeheerDeelnemenSaga(action: AnyAction): any {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(api.updateVereenvoudigdBeheerData, accessToken, { deelnemer: true, akkoordPubliekContact: false });
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DEELNEMEN_OK" });
	} catch (err) {
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DEELNEMEN_ERROR", error: err });
	}
}

function* getLaatsteVereenvoudigdBeheerInvoerSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const data = yield call(api.getLaatsteVereenvoudigdBeheerInvoer, accessToken);
		yield(put(mijnActions.getLaatsteVereenvoudigdBeheerInvoerOk(data)));
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* vereenvoudigdBeheerLoadDataSaga(action: AnyAction): any {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const data = yield call(api.loadVereenvoudigdBeheerData, accessToken);
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DATA_LOADED", payload: data });
	} catch (err) {
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DATA_LOAD_ERROR", error: err });
	}
}

function* vereenvoudigdBeheerSendDataSaga(action: AnyAction): any {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(api.sendVereenvoudigdBeheerData, accessToken, action.payload);
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DATA_SENT" });
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DATA_LOAD" });
	} catch (err) {
		yield put({ type: "MY_VEREENVOUDIGD_BEHEER_DATA_ERROR", error: err });
	}
}

function* saveVereenvoudigdBeheerInvoerSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(api.bulkInsertVereenvoudigdBeheerData, accessToken, action.payload);
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}


import { notification } from "antd";
import { User, UserManager } from "oidc-client";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";

interface AppCallbackComponentProps extends RouteComponentProps<{}> {
    readonly userManager: UserManager;
}

class AppCallbackComponent extends Component<AppCallbackComponentProps> {

    constructor(props: AppCallbackComponentProps) {
        super(props);
    }

    onSuccess(user: User) {
        const returnTo = user.state ? user.state.returnTo || "/mijn" : "/mijn";
        this.props.history.push(returnTo);
    }

    onError(error: Error) {
        notification.error(
            {
                message: "Fout",
                description: "Er is iets fout gelopen bij het aanmelden. Probeer opnieuw of contacteer SLE via info@sle.be"
            }
        );
        this.props.history.push("/login");
    }

    render() {
        const userManager = this.props.userManager;
        return (
            <CallbackComponent
                successCallback={this.onSuccess.bind(this)}
                errorCallback={this.onError.bind(this)}
                userManager={userManager}>
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }
}

export const AppCallback = withRouter(connect()(AppCallbackComponent));

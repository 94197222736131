import axios from "axios";
import { config } from "../config";

export async function create(accessToken: string, values: any): Promise<void> {
    const response = await axios.post(config.apiUri + "/members", values, { headers: { authorization: "Bearer " + accessToken } });
    return response.data;
}

export async function update(accessToken: string, mid: number, values: any): Promise<void> {
    await axios.put(config.apiUri + "/members/" + mid, values, { headers: { authorization: "Bearer " + accessToken } });
}

export async function deleteMember(accessToken: string, mid: number): Promise<void> {
    await axios.delete(config.apiUri + "/members/" + mid, { headers: { authorization: "Bearer " + accessToken } });
}

export async function findAll(accessToken: string): Promise<any[]> {
    const response = await axios.get(config.apiUri + "/members", { headers: { authorization: "Bearer " + accessToken } });
    return response.data;
}

export async function findAllEvents(accessToken: string, mid: number): Promise<any[]> {
    const response = await axios.get(
        config.apiUri + "/members/" + mid + "/events",
        { headers: { authorization: "Bearer " + accessToken } }
    );
    return response.data;
}

export async function getSubscription(accessToken: string, mid: number): Promise<any> {
    const response = await axios.get(
        config.apiUri + "/members/" + mid + "/subscription",
        { headers: { authorization: "Bearer " + accessToken } }
    );
    return response.data;
}

export async function cancelSubscription(accessToken: string, mid: number): Promise<any> {
    await axios.delete(
        config.apiUri + "/members/" + mid + "/subscription",
        { headers: { authorization: "Bearer " + accessToken } }
    );
}

export async function getBreeder(accessToken: string, mid: number): Promise<any> {
    const response = await axios.get(
        config.apiUri + "/members/" + mid + "/breeder",
        { headers: { authorization: "Bearer " + accessToken } }
    );
    return response.data;
}

export async function updateBreeder(accessToken: string, mid: number, breeder: any): Promise<any> {
    const response = await axios.post(
        config.apiUri + "/members/" + mid + "/breeder",
        breeder,
        { headers: { authorization: "Bearer " + accessToken } }
    );
    return response.data;
}

export async function registerPayment(accessToken: string, mid: number, payment: any): Promise<void> {
    await axios.post(
        config.apiUri + "/members/" + mid + "/payment",
        payment,
        { headers: { authorization: "Bearer " + accessToken } }
    );
}

export async function getStatistiek(accessToken: string): Promise<any> {
    const response = await axios.get(
        config.apiUri + "/members/statistiek",
        { headers: { authorization: "Bearer " + accessToken } }
    );
    return response.data;
}

export async function getWebUser(accessToken: string, mid: number): Promise<any> {
    const response = await axios.get(
        config.apiUri + "/members/" + mid + "/webuser",
        { headers: { authorization: "Bearer " + accessToken } }
    );
    return response.data;
}

export async function linkWebUser(accessToken: string, mid: number, uid: string): Promise<void> {
    await axios.post(
        config.apiUri + "/members/" + mid + "/link",
        { uid: uid },
        { headers: { authorization: "Bearer " + accessToken } }
    );
}

export async function unlinkWebUser(accessToken: string, mid: number): Promise<void> {
    await axios.delete(
        config.apiUri + "/members/" + mid + "/webuser",
        { headers: { authorization: "Bearer " + accessToken } }
    );
}

export async function updateSubscriptionsToRenew(accessToken: string): Promise<void> {
    await axios.post(
        config.apiUri + "/members/subscriptionsToRenew",
        {},
        { headers: { authorization: "Bearer " + accessToken } }
    );
}

export async function getSubscriptionPayments(accessToken: string): Promise<any> {
	const response = await axios.get(
		config.apiUri + "/payments",
        { headers: { authorization: "Bearer " + accessToken } }		
	);

	return response.data;
}
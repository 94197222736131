
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { reducer as authReducer, UserState } from "redux-oidc";
import createSagaMiddleware from "redux-saga";
import createReduxWaitForMiddleware from "redux-wait-for-action";
import { dataReducer, dataSaga, DataState } from "./data";
import { memberReducer, memberSaga, MemberState } from "./member";
import { mijnReducer, mijnSaga, MijnState } from "./mijn";
import { qrCodesReducer, qrCodesSaga, QrCodesState } from "./qr";
import { userDataReducer, userDataSaga, UserDataState } from "./userData";
import { vereenvoudigdBeheerReducer, vereenvoudigdBeheerSaga, VereenvoudigdBeheerState } from "./vereenvoudigdBeheer";
import { websiteReducer, websiteSaga, WebsiteState } from "./website";
import { UiStoreState, uiReducer } from "./ui";

const sagaMiddleware = createSagaMiddleware();

export interface StoreState {
    ui: UiStoreState;
    auth: UserState;
    userData: UserDataState;
    member: MemberState;
    data: DataState;
    mijn: MijnState;
    website: WebsiteState;
    qrCodes: QrCodesState;
    vereenvoudigdBeheer: VereenvoudigdBeheerState;
}

export const store = createStore(
    combineReducers({
        ui: uiReducer,
        auth: authReducer,
        userData: userDataReducer,
        member: memberReducer,
        data: dataReducer,
        mijn: mijnReducer,
        website: websiteReducer,
        qrCodes: qrCodesReducer,
        vereenvoudigdBeheer: vereenvoudigdBeheerReducer
    }),
    compose(
        applyMiddleware(sagaMiddleware),
        applyMiddleware(createReduxWaitForMiddleware())
    )
);

sagaMiddleware.run(userDataSaga);
sagaMiddleware.run(memberSaga);
sagaMiddleware.run(dataSaga);
sagaMiddleware.run(mijnSaga);
sagaMiddleware.run(websiteSaga);
sagaMiddleware.run(qrCodesSaga);
sagaMiddleware.run(vereenvoudigdBeheerSaga);

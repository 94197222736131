import { produce } from "immer";
import { cloneDeep, extend, keyBy } from "lodash";
import { AnyAction } from "redux";
import { createAction } from "redux-actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ERROR_ACTION, WAIT_FOR_ACTION } from "redux-wait-for-action";
import { StoreState } from ".";
import { cancelSubscription, create, deleteMember, findAll, findAllEvents, getBreeder, getStatistiek, getSubscription, getWebUser, linkWebUser, registerPayment, unlinkWebUser, update, updateBreeder, updateSubscriptionsToRenew } from "../api/members";
import { getReportBetalingsUitnodiging, getReportMailingLists, getReportMembersVsWebUsers, getReportVerzendlijst, Report } from "../api/reports";
import { completeAsyncUiAction, startAsyncUiAction } from "./ui";
import * as api from "../api/members";

export interface MemberState {
	members: { [id: number]: any };
	events: { [id: number]: any };
	subscriptions: any;
	breeders: any;
	statistiek: any;
	webusers: any;
	subscriptionPayments: any;
}

const initialState: MemberState = {
	members: {},
	events: {},
	subscriptions: {},
	breeders: {},
	statistiek: {},
	webusers: {},
	subscriptionPayments: [],
};

export const memberActions = {
	findAll: (): AnyAction => {
		return { type: "MEMBER_LOAD_ALL" };
	},
	findEvents: (mid: number): AnyAction => {
		return { type: "MEMBER_LOAD_ALL_EVENTS", payload: mid };
	},
	getSubscription: createAction("members/lidmaatschap/get"),
	cancelSubscription: createAction<number>("members/lidmaatschap/cancel"),
	getBreeder: (mid: number): AnyAction => {
		return { type: "MEMBER_GET_BREEDER", payload: mid };
	},
	updateBreeder: (mid: number, breeder: any): AnyAction => {
		return {
			type: "MEMBER_BREEDER_UPDATE",
			[WAIT_FOR_ACTION]: "MEMBER_BREEDER_UPDATED",
			[ERROR_ACTION]: "MEMBER_BREEDER_UPDATE_ERROR",
			payload: { mid, breeder }
		};
	},
	getWebUser: (mid: number): AnyAction => {
		return { type: "MEMBER_WEBUSER_LOAD", payload: mid };
	},
	createMember: createAction("members/contactgegevens/create"),
	updateMember: createAction("members/contactgegevens/update"),
	updateMemberOk: createAction("members/contactgegevens/update/ok"),
	deleteMember: createAction("members/delete"),
	deleteMemberOk: createAction("members/delete/ok"),
	registerSubscriptionPayment: createAction("members/subscription/payment/register"),
	loadStatistiek: (): AnyAction => {
		return { type: "MEMBER_STATISTIEK_LOAD" };
	},
	linkWebUser: (mid: number, uid: string): AnyAction => {
		return {
			type: "MEMBER_WEBUSER_LINK",
			[WAIT_FOR_ACTION]: "MEMBER_WEBUSER_LINKED",
			[ERROR_ACTION]: "MEMBER_WEBUSER_LINK_ERROR",
			payload: { mid: mid, uid: uid }
		};
	},
	unlinkWebUser: (mid: number): AnyAction => {
		return {
			type: "MEMBER_WEBUSER_UNLINK",
			[WAIT_FOR_ACTION]: "MEMBER_WEBUSER_UNLINK_OK",
			[ERROR_ACTION]: "MEMBER_WEBUSER_UNLINK_ERROR",
			payload: { mid }
		};
	},
	downloadReportVerzendlijst: createAction("members/reports/verzendlijst"),
	downloadReportBetalingsUitnodiging: createAction("members/reports/betalingsuitnodiging"),
	downloadMembersVsWebUsers: createAction("members/reports/membersvswebusers"),
	downloadMailingLists: createAction("members/reports/mailinglists"),
	updateSubscriptionsToRenew: (): AnyAction => {
		return {
			type: "MEMBER_UPDATE_SUBSCRIPTIONS_TO_RENEW",
			[WAIT_FOR_ACTION]: "MEMBER_UPDATE_SUBSCRIPTIONS_TO_RENEW_OK",
			[ERROR_ACTION]: "MEMBER_UPDATE_SUBSCRIPTIONS_TO_RENEW_ERROR",
			payload: {}
		};
	},
	getSubscriptionPayments: createAction("members/subscriptionpayments/get"),
	getSubscriptionPaymentsOk: createAction<any>("members/subscriptionpayments/get/ok")
};

export function memberReducer(state: MemberState = initialState, action: AnyAction): MemberState {
	if (!action) {
		return extend({}, state);
	}

	switch (action.type) {
		case memberActions.updateMemberOk.toString():
			return produce(state, draft => {
				draft.members[action.payload.id] = action.payload.values;
				delete draft.events[action.payload.id];
			});
		case memberActions.deleteMemberOk.toString():
			return produce(state, draft => {
				delete draft.members[action.payload];
				delete draft.events[action.payload];
				delete draft.subscriptions[action.payload];
				delete draft.webusers[action.payload];
			});
		case "MEMBER_ALL_LOADED":
			return extend({}, state, { members: keyBy(action.payload, "id") });
		case "MEMBER_ALL_EVENTS_LOADED":
			const events = cloneDeep(state.events);
			events[action.payload.mid] = action.payload.events;
			return extend({}, state, { events: events });
		case "MEMBER_SUBSCRIPTION_LOADED":
			const subscriptions = cloneDeep(state.subscriptions);
			subscriptions[action.payload.mid] = action.payload.subscription;
			return extend({}, state, { subscriptions: subscriptions });
		case "MEMBER_BREEDER_LOADED":
		case "MEMBER_BREEDER_UPDATED":
			const breeders = cloneDeep(state.breeders);
			breeders[action.payload.mid] = action.payload.breeder;
			return extend({}, state, { breeders: breeders });
		case "MEMBER_WEBUSER_LOADED":
			const webusers = cloneDeep(state.webusers);
			webusers[action.payload.mid] = action.payload.webuser;
			return extend({}, state, { webusers: webusers });
		case "MEMBER_STATISTIEK_LOADED":
			return extend({}, state, { statistiek: action.payload });
		case "MEMBER_WEBUSER_UNLINK_OK":
			const webusers2 = cloneDeep(state.webusers);
			delete webusers2[action.payload.mid];
			return extend({}, state, { webusers: webusers2 });
		case memberActions.getSubscriptionPaymentsOk.toString():
			return extend({}, state, { subscriptionPayments: action.payload });
		default:
			return extend({}, state);
	}
}

export function* memberSaga() {
	yield takeLatest("MEMBER_LOAD_ALL", loadAllMemberSaga);
	yield takeLatest("MEMBER_LOAD_ALL_EVENTS", loadAllMemberEventsSaga);
	yield takeLatest(memberActions.createMember.toString(), createMemberSaga);
	yield takeLatest(memberActions.getSubscription.toString(), getSubscriptionSaga);
	yield takeLatest("MEMBER_GET_BREEDER", getBreederSaga);
	yield takeLatest("MEMBER_BREEDER_UPDATE", updateBreederSaga);
	yield takeLatest("MEMBER_WEBUSER_LOAD", loadWebUserSaga);
	yield takeLatest(memberActions.updateMember.toString(), updateMemberSaga);
	yield takeLatest(memberActions.deleteMember.toString(), deleteMemberSaga);
	yield takeLatest(memberActions.registerSubscriptionPayment.toString(), registerSubscriptionPaymentSaga);
	yield takeLatest("MEMBER_STATISTIEK_LOAD", loadStatistiekSaga);
	yield takeLatest("MEMBER_WEBUSER_LINK", linkWebUserSaga);
	yield takeLatest("MEMBER_WEBUSER_UNLINK", unlinkWebUserSaga);
	yield takeLatest(memberActions.downloadReportVerzendlijst.toString(), downloadVerzendlijstSaga);
	yield takeLatest(memberActions.downloadReportBetalingsUitnodiging.toString(), downloadBetalingsUitnodigingSaga);
	yield takeLatest(memberActions.downloadMembersVsWebUsers.toString(), downloadMembersVsWebUsersSaga);
	yield takeLatest(memberActions.downloadMailingLists.toString(), downloadMailingListsSaga);
	yield takeLatest(memberActions.cancelSubscription.toString(), cancelSubscriptionSaga);
	yield takeLatest("MEMBER_UPDATE_SUBSCRIPTIONS_TO_RENEW", updateSubscriptionsToRenewSaga);
	yield takeLatest(memberActions.getSubscriptionPayments.toString(), getSubscriptionPaymentsSaga);
}

function* createMemberSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(create, accessToken, action.payload);
		yield completeAsyncUiAction(action.type, null, result);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* updateMemberSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(update, accessToken, action.payload.mid, action.payload.values);
		yield put(memberActions.updateMemberOk(action.payload));
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* deleteMemberSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(deleteMember, accessToken, action.payload);
		yield completeAsyncUiAction(action.type);
		yield put(memberActions.deleteMemberOk(action.payload));
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* loadAllMemberSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	const members = yield call(findAll, accessToken);
	yield put({ type: "MEMBER_ALL_LOADED", payload: members });
}

function* loadAllMemberEventsSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	const events = yield call(findAllEvents, accessToken, action.payload);
	yield put({ type: "MEMBER_ALL_EVENTS_LOADED", payload: { mid: action.payload, events: events } });
}

function* getSubscriptionSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	const subscription = yield call(getSubscription, accessToken, action.payload);
	yield put({ type: "MEMBER_SUBSCRIPTION_LOADED", payload: { mid: action.payload, subscription: subscription } });
}

function* getBreederSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	const breeder = yield call(getBreeder, accessToken, action.payload);
	yield put({ type: "MEMBER_BREEDER_LOADED", payload: { mid: action.payload, breeder: breeder } });
}

function* updateBreederSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		const breeder = yield call(updateBreeder, accessToken, action.payload.mid, action.payload.breeder);
		yield put({ type: "MEMBER_BREEDER_UPDATED", payload: { mid: action.payload.mid, breeder } });
	} catch (err) {
		yield put({ type: "MEMBER_BREEDER_UPDATE_ERROR" });
	}
}

function* registerSubscriptionPaymentSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(registerPayment, accessToken, action.payload.mid, action.payload.values);
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* loadStatistiekSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	const statistiek = yield call(getStatistiek, accessToken);
	yield put({ type: "MEMBER_STATISTIEK_LOADED", payload: statistiek });
}

function* loadWebUserSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		const webuser = yield call(getWebUser, accessToken, action.payload);
		yield put({ type: "MEMBER_WEBUSER_LOADED", payload: { mid: action.payload, webuser } });
	} catch (err) {
		if (err.response && err.response.status === 404) {
			yield put({ type: "MEMBER_WEBUSER_LOADED", payload: { mid: action.payload, webuser: null } });
		} else {
			yield put({ type: "GENERAL_ERROR", payload: err });
		}
	}
}

function* linkWebUserSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		yield call(linkWebUser, accessToken, action.payload.mid, action.payload.uid);
		yield put({ type: "MEMBER_WEBUSER_LINKED" });
	} catch (err) {
		yield put({ type: "MEMBER_WEBUSER_LINK_ERROR" });
	}
}

function* unlinkWebUserSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		yield call(unlinkWebUser, accessToken, action.payload.mid);
		yield put({ type: "MEMBER_WEBUSER_UNLINK_OK", payload: { mid: action.payload.mid } });
	} catch (err) {
		yield put({ type: "MEMBER_WEBUSER_UNLINK_ERROR", error: err });
	}
}

function* downloadVerzendlijstSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const report: Report = yield call(getReportVerzendlijst, accessToken);
		yield completeAsyncUiAction(action.type, null, report);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* downloadBetalingsUitnodigingSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const report: Report = yield call(getReportBetalingsUitnodiging, accessToken, { kwartaal: action.payload, statusBijwerken: false });
		yield completeAsyncUiAction(action.type, null, report);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* downloadMembersVsWebUsersSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const report: Report = yield call(getReportMembersVsWebUsers, accessToken);
		yield completeAsyncUiAction(action.type, null, report);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* downloadMailingListsSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const report: Report = yield call(getReportMailingLists, accessToken);
		yield completeAsyncUiAction(action.type, null, report);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* cancelSubscriptionSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(cancelSubscription, accessToken, action.payload);
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

function* updateSubscriptionsToRenewSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	try {
		yield call(updateSubscriptionsToRenew, accessToken);
		yield put({ type: "MEMBER_UPDATE_SUBSCRIPTIONS_TO_RENEW_OK", payload: null });
	} catch (err) {
		yield put({ type: "MEMBER_UPDATE_SUBSCRIPTIONS_TO_RENEW_ERROR", payload: err });
	}
}

function* getSubscriptionPaymentsSaga(action: AnyAction): any {
	const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
	const sps = yield call(api.getSubscriptionPayments, accessToken);
	yield put(memberActions.getSubscriptionPaymentsOk(sps));
}
import { Alert, Button, Form, Select, Spin } from 'antd';
import { filter, isEmpty, map } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { memberActions } from '../../store/member';
import { websiteActions } from '../../store/website';


export const LinkWebUser: React.FC<{ mid: number }> = (props) => {

	const dispatch = useDispatch();
	const [status, setStatus] = React.useState<'none' | 'saving' | 'saved' | 'error'>('none');
	const [webUserSelected, setWebUserSelected] = useState<boolean>(false);
	const [alert, setAlert] = useState<React.ReactNode>(null);

	const { webusers } = useSelector((state: StoreState) => ({
		webusers: state.website.webusers
	}));

	useEffect(() => {
		dispatch(websiteActions.loadWebUsers());
	}, []);

	useEffect(() => {
		switch (status) {
			case 'saving':
				setAlert(<Alert message="De gegevens worden opgeslaan" type="info" />);
				break;
			case "error":
				setAlert(<Alert message="Er is een fout opgetreden bij het opslaan van de gegevens" type="error" />);
				break;
		}
	}, [status]);

	if (isEmpty(webusers)) {
		return (
			<div>
				<p>De web-gebruikers worden geladen...</p>
				<Spin />
			</div>
		);
	}

	const onChange = (value: any) => {
		setWebUserSelected(!isEmpty(value));
	};

	const onFinish = (values: any) => {
		setStatus('saving');
		dispatch(memberActions.linkWebUser(props.mid, values.webuser))
			.then(() => {
				dispatch(memberActions.getWebUser(props.mid));
				setStatus('saved');
			})
			.catch(() => {
				setStatus('error');
			});
	};

	return (
		<Fragment>
			{alert}
			<p>Er is aan deze gegevens nog geen web-gebruiker gekoppeld. Kies de webgebruiker en klik op koppelen.</p>
			<Form layout="inline" onFinish={onFinish}>
				<Form.Item label="Gebruikersnaam" name="webuser" rules={[{ required: true, message: 'Gelieve een webgebruiker te selecteren' }]}>
					<Select options={webusers} showSearch optionFilterProp="label" onChange={onChange} style={{ width: "400px" }} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" disabled={!webUserSelected}>Koppelen</Button>
				</Form.Item>
			</Form>
		</Fragment>
	);

};

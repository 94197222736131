
import { notification, Spin } from "antd";
import { extend, includes } from "lodash";
import { parse } from "qs";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../store";
import { mijnActions } from "../../store/mijn";

interface LidgeldBetalenStatusComponentProps extends RouteComponentProps<{}> {
    loadMyPayment: (pid: string) => Promise<AnyAction>;
    payment: any;
}

interface LidgeldBetalenStatusComponentState {
    status: "pid-not-found" | "none";
}

class LidgeldBetalenStatusComponent extends React.Component<LidgeldBetalenStatusComponentProps, LidgeldBetalenStatusComponentState> {

    constructor(props: LidgeldBetalenStatusComponentProps) {
        super(props);

        this.state = { status: "none" };
    }

    componentWillMount() {
        const q = parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (q.pid) {
            this.props.loadMyPayment(q.pid)
                .catch(() => {
                    notification.error({
                        key: this.state.status,
                        message: "Fout",
                        description: "Er is iets fout gelopen bij het laden van de data, probeer opnieuw of contacteer SLE."
                    });
                });
        } else {
            this.setState({ status: "pid-not-found" });
        }
    }

    render() {
        if (this.state.status === "pid-not-found") {
            notification.error({ key: this.state.status, message: "Fout", description: "Er is iets fout gelopen met het adres van deze pagina." });
            return (
                <div>
                    <h2>Betaling lidgeld</h2>
                </div>
            );
        }

        if (!this.props.payment) {
            return (
                <div>
                    <h2>Betaling lidgeld</h2>
                    <Spin />
                </div>
            );
        }

        let message: React.ReactNode;
        if (this.props.payment.status === "paid") {
            message = <p>We hebben uw betaling goed ontvangen.</p>;
        } else if (includes(["open", "pending", "authorized"], this.props.payment.status)) {
            message = <p>We wachten nog even op bevestiging van de ontvangst van uw betaling.</p>;
        } else {
            message = <p>Uw betaling kon niet verwerkt worden. Neem contact op met SLE.</p>;
        }

        return (
            <div>
                <h2>Betaling lidgeld</h2>
                {message}
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return extend(
        {},
        ownProps,
        {
            payment: state.mijn.payment
        }
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadMyPayment: (pid: string): Promise<AnyAction> => {
            return dispatch(mijnActions.loadSubscriptionPayment(pid));
        }
    }
};

export const LidgeldBetalenStatus = withRouter(connect(mapStateToProps, mapDispatchToProps)(LidgeldBetalenStatusComponent));

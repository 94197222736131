
import { message, Switch } from "antd";
import { cloneDeep } from "lodash";
import React from "react";
import { Ras } from "../../store/vereenvoudigdBeheer";

interface RasSwitchProps {
    ras: Ras;
    onChange: (ras: Ras) => Promise<void>;
}

interface RasSwitchState {
    loading: boolean;
}

export class RasSwitch extends React.Component<RasSwitchProps, RasSwitchState> {
    constructor(props: RasSwitchProps) {
        super(props);

        this.state = {
            loading: false
        };
    }

    async toggle(checked: boolean, e: MouseEvent): Promise<void> {
        e.preventDefault();
        this.setState({ loading: true });
        try {
            const rasUpdate = cloneDeep(this.props.ras);
            rasUpdate.actief = checked;
            await this.props.onChange(rasUpdate);
        } catch (err) {
            message.error(err);
            this.props.ras.actief = !checked;
            this.setState({ loading: false });
        }
    }

    render() {
        return <Switch loading={this.state.loading} defaultChecked={this.props.ras.actief} onClick={(c, e) => this.toggle(c, e)} />;
    }
}

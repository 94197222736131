import { EditOutlined, EuroOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Form, Input, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { filter, values } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";
import { RegisterPaymentModal } from "./RegisterPaymentModal";
import { SubscriptionStatusBadge } from "./SubscriptionStatusBadge";

function getColumns(openMember: (id: number) => void, registerPayment: (id: number) => void): ColumnsType<Member> {
	return [
		{
			key: "id",
			render: (text: any, record: Member) => {
				return (<div><Button shape="circle" icon={<EditOutlined />} onClick={() => openMember(record.id)} /></div>);
			},
			width: "60px"
		},
		{
			key: "lidnummer",
			title: "Lidnummer",
			render: (text: any, record: Member) => {
				if (record.lidnummer) {
					return (<div><SubscriptionStatusBadge status={record.status} text={record.lidnummer} /></div>);
				} else {
					return "";
				}
			},
			sorter: (a: Member, b: Member) => (a.lidnummer || 0) < (b.lidnummer || 0) ? -1 : 1,
			width: "150px"
		},
		{
			title: "Titel",
			dataIndex: "titel",
			key: "titel",
			width: "100px"
		},
		{
			title: "Naam",
			dataIndex: "naam",
			key: "naam",
			defaultSortOrder: "ascend",
			sorter: (a: Member, b: Member) => (a.naam || "").toLocaleLowerCase() < (b.naam || "").toLocaleLowerCase() ? -1 : 1,
			width: "300px"
		},
		{
			title: "Voornaam",
			dataIndex: "voornaam",
			key: "voornaam",
			width: "200px"
		},
		{
			title: "Organisatie",
			dataIndex: "organisatie",
			key: "organisatie",
			width: "250px"
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email"
		},
		{
			dataIndex: "actions",
			key: "actions",
			render: (text: any, record: Member) => {
				return (
					<Button icon={<EuroOutlined />} disabled={record.status == "actief"} onClick={() => registerPayment(record.id)} />
				)
			}
		}
	];
}

function stringIncludes(property: string, val: string): boolean {
	return (property || "").toString().toLowerCase().includes((val || "").toLowerCase());
}

function checkStatus(property: string, selected: string[]): boolean {
	if ((selected || []).length < 1) {
		return true;
	}
	else {
		return selected.indexOf(property) > -1;
	}
}

function filterMembers(members: Member[], filterValues: FilterState): Member[] {
	return filter(members, member => {
		return stringIncludes(member.lidnummer, filterValues.lidnummer)
			&& (
				stringIncludes(member.naam, filterValues.naam)
				|| stringIncludes(member.voornaam, filterValues.naam)
				|| stringIncludes(member.organisatie, filterValues.naam)
			)
			&& (
				stringIncludes(member.adres1, filterValues.adres)
				|| stringIncludes(member.postcode, filterValues.adres)
				|| stringIncludes(member.gemeente, filterValues.adres)
				|| stringIncludes(member.land, filterValues.adres)
			)
			&& stringIncludes(member.email, filterValues.email)
			&& checkStatus(member.status, filterValues.status);
	});
}

interface FilterState {
	status: string[];
	lidnummer: string;
	naam: string;
	adres: string;
	email: string;
}

interface Member {
	id: number;
	naam: string;
	[key: string]: any;
}


const emptyFilter: FilterState = {
	status: ["actief", "te-vernieuwen"],
	lidnummer: "",
	naam: "",
	adres: "",
	email: ""
};

export const MemberList = (props: { goToMemberDetails: (mid: number) => void }) => {

	const [filter, setFilter] = useState<FilterState>(emptyFilter);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [memberIdForPayment, setMemberIdForPayment] = useState<number>(0);

	const { members } = useSelector((store: StoreState) => ({
		members: values(store.member.members)
	}));

	useEffect(
		() => {
			dispatch(memberActions.findAll());
		},
		[false]
	);

	function onFinishSearch(values: any) {
		console.log(values);
		setFilter(values);
	}

	function resetSearch() {
		form.resetFields();
		setFilter(emptyFilter);
	}

	function onPaymentModalClose() {
		setMemberIdForPayment(0);
		dispatch(memberActions.findAll());
	}

	return (
		<div>
			<h2>Ledenbeheer</h2>
			<Card title="Zoeken" bordered={true}>
				<Form layout="inline" form={form} onFinish={onFinishSearch}>
					<Form.Item name="status">
						<Select mode="multiple" allowClear placeholder="Status" style={{ width: 200 }} defaultValue={emptyFilter.status}>
							<Select.Option value="actief" title="Actief" key="actief"><Badge status="success" /> Actief</Select.Option>
							<Select.Option value="te-vernieuwen" title="Te vernieuwen" key="te-vernieuwen"><Badge status="warning" /> Te vernieuwen</Select.Option>
							<Select.Option value="inactief" title="Inactief" key="inactief"><Badge status="default" /> Inactief</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="lidnummer"><Input placeholder="Lidnummer" /></Form.Item>
					<Form.Item name="naam"><Input placeholder="Naam / Voornaam / Organisatie" /></Form.Item>
					<Form.Item name="adres"><Input placeholder="Adres" /></Form.Item>
					<Form.Item name="email"><Input placeholder="Email" /></Form.Item>
					<Form.Item><Button type="primary" icon={<SearchOutlined />} htmlType="submit" /></Form.Item>
					<Form.Item><Button type="default" icon={<ReloadOutlined />} onClick={() => resetSearch()} /></Form.Item>
				</Form>
			</Card>
			<RegisterPaymentModal memberId={memberIdForPayment} onClose={() => onPaymentModalClose()} />
			<Table
				columns={getColumns(props.goToMemberDetails, (mid: number) => setMemberIdForPayment(mid))}
				rowKey="id"
				dataSource={filterMembers(members, filter)}
				size="middle"
				pagination={{ pageSize: 25, position: "both" }}
			/>
		</div>
	);
};

import Icon from "@ant-design/icons";
import { Button, Descriptions, notification, Popconfirm, Spin } from "antd";
import { extend, has } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";
import { LinkWebUser } from "./LinkWebUser";

interface WebComponentProps {
	mid: number;

	webuser: any;
	webuserLoaded: boolean;

	loadWebUser: (mid: number) => void;
	unlinkWebUser: (mid: number) => Promise<AnyAction>;
}

class WebComponent extends React.Component<WebComponentProps> {

	componentDidMount() {
		this.props.loadWebUser(this.props.mid);
	}

	async unlinkWebUser() {
		notification.info({ key: "unlink", message: "Even geduld...", description: "De webgebruiker wordt ontkoppeld." });
		try {
			await this.props.unlinkWebUser(this.props.mid);
			notification.success({ key: "unlink", message: "Ok", description: "De webgebruiker werd ontkoppeld." });
		} catch (err: any) {
			notification.error({ key: "unlink", message: "Oeps", description: err.message });
		}
	}

	render() {
		if (!this.props.webuserLoaded) {
			return (
				<div>
					<p>De gegevens worden geladen</p>
					<Spin />
				</div>
			);
		}

		if (!this.props.webuser) {
			return (<LinkWebUser mid={this.props.mid} />);
		}

		return (
			<div>
				<p>Deze gegevens zijn gekoppeld aan volgende web-gebruiker.</p>
				<Descriptions key="content">
					<Descriptions.Item label="Gebruikersnaam">{this.props.webuser.name}</Descriptions.Item>
					<Descriptions.Item label="Email">{this.props.webuser.mail}</Descriptions.Item>
				</Descriptions>
				<Button type="default" style={{ marginRight: "16px" }} target="_blank" href={"https://sle.be/#overlay=user/" + this.props.webuser.uid + "/edit"}>Profiel aanpassen</Button>
				<Popconfirm
					icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
					title="Deze webgebruiker ontkoppelen? Er worden geen gegevens gewist maar de webgebruiker verliest zijn privileges als lid op de website. De koppeling kan altijd hersteld worden."
					okText="Ja" cancelText="Nee" onConfirm={() => this.unlinkWebUser()}>
					<Button danger>Webgebruiker ontkoppelen</Button>
				</Popconfirm>
			</div>
		);
	}
}

const mapStateToProps = (state: StoreState, ownProps: any) => {
	return extend(
		{},
		ownProps,
		{
			webuserLoaded: has(state.member.webusers, ownProps.mid),
			webuser: state.member.webusers[ownProps.mid]
		}
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return {
		loadWebUser: (mid: number): void => {
			dispatch(memberActions.getWebUser(mid));
		},
		unlinkWebUser: (mid: number): Promise<AnyAction> => {
			return dispatch(memberActions.unlinkWebUser(mid));
		}
	};
};

export const Web = connect(mapStateToProps, mapDispatchToProps)(WebComponent);


import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Descriptions, Form, Input, InputNumber, Modal, notification, Popconfirm, Radio, Space } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";
import { getAsyncUiState } from "../../store/ui";
import { RegisterPaymentModal } from "./RegisterPaymentModal";
import { SubscriptionStatusBadge } from "./SubscriptionStatusBadge";

export const Lidmaatschap = (props: { mid: number }) => {
	const dispatch = useDispatch();
	const [asyncCancelState, resetAsyncCancelState] = getAsyncUiState(memberActions.cancelSubscription.toString());
	const [paymentForMemberId, setPaymentForMemberId] = useState<number>(0);

	const { subscription } = useSelector((store: StoreState) => ({
		subscription: store.member.subscriptions[props.mid]
	}));

	useEffect(
		() => {
			dispatch(memberActions.getSubscription(props.mid));
		},
		[false]
	);

	useEffect(
		() => {
			if (asyncCancelState.isRunning) {
				notification.info({ key: "cancel", message: "Even geduld", description: "Het lidmaatschap wordt beëindigd" });
			}

			if (asyncCancelState.hasCompleted) {
				if (asyncCancelState.error) {
					notification.error({ key: "cancel", message: "Oeps...", description: "Fout: " + asyncCancelState.error.toString() });
				} else {
					notification.success({ key: "cancel", message: "Ok", description: "Het lidmaatschap werd beëindigd" });
					dispatch(memberActions.getSubscription(props.mid));
				}

				resetAsyncCancelState();
			}
		},
		[asyncCancelState]
	);

	function closePaymentModal() {
		dispatch(memberActions.getSubscription(props.mid));
		setPaymentForMemberId(0);
	}

	function stopSubscription() {
		dispatch(memberActions.cancelSubscription(props.mid));
	}

	return (
		<Fragment>
			<RegisterPaymentModal memberId={paymentForMemberId} onClose={() => closePaymentModal()} />
			<Space direction="vertical" size={24}>
				<Space>
					<Button onClick={() => setPaymentForMemberId(props.mid)}>Betaling ingeven</Button>
					{!isEmpty(subscription) && subscription.status !== "inactief" && (
						<Popconfirm
							icon={<QuestionCircleOutlined color="red" />}
							title="Dit lidmaatschap be&euml;indigen?"
							onConfirm={stopSubscription} okText="Ja" cancelText="Nee">
							<Button danger>Lidmaatschap be&euml;indigen</Button>
						</Popconfirm>
					)}
				</Space>
				{!isEmpty(subscription) && (
					<Descriptions title="Lidmaatschap">
						<Descriptions.Item label="Lidnummer">{subscription.lidnummer}</Descriptions.Item>
						<Descriptions.Item label="Type">{subscription.type}</Descriptions.Item>
						<Descriptions.Item label="Status"><SubscriptionStatusBadge status={subscription.status} text={subscription.status} /></Descriptions.Item>
						<Descriptions.Item label="Begin">{subscription.begin}</Descriptions.Item>
						<Descriptions.Item label="Einde">{subscription.einde}</Descriptions.Item>
					</Descriptions>
				)}
				{isEmpty(subscription) && (
					<p>Nog geen lid</p>
				)}
			</Space>
		</Fragment>
	);
};

import { Button, Card, Form, Input, List, message } from "antd";
import { filter, isEmpty, NumericDictionary } from "lodash";
import React, { FormEvent, Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { StoreState } from "../../store";
import { Ras, vereenvoudigdBeheerActions } from "../../store/vereenvoudigdBeheer";
import { Kleuren } from "./Kleuren";
import { RasSwitch } from "./RasSwitch";

export type RassenLijstProps = RassenLijstStaticProps & RassenLijstStoreProps & RassenLijstDispatchProps & FormComponentProps;

interface RassenLijstStaticProps {
	type: "actieve" | "inactieve";
}

interface RassenLijstStoreProps {
	rassen: Ras[];
	kleuren: NumericDictionary<string[]>;
}

interface RassenLijstDispatchProps {
	updateRas(ras: Ras): Promise<void>;
}

interface RassenLijstState {
	filter?: string;
}

export const RassenLijst: React.ComponentType<any> = (props) => {

	const dispatch = useDispatch();

	const [filter, setFilter] = useState<string>();
	const [filteredRassen, setFilteredRassen] = useState<Ras[]>([]);

	const { rassen, kleuren } = useSelector((store: StoreState) => ({
		rassen: store.vereenvoudigdBeheer.rassen,
		kleuren: store.vereenvoudigdBeheer.kleuren
	}));

	useEffect(() => {
		setFilteredRassen(rassen);
	}, [rassen, kleuren, filter]);

	const toggleRas = async (ras: Ras): Promise<void> => {
		dispatch(vereenvoudigdBeheerActions.updateRas(ras))
	};

	return (
		<Fragment>
			<Form onFinish={(values: any) => { setFilter(values.naam) }} initialValues={{ naam: filter }} style={{ marginBottom: "16px" }}>
				<Form.Item name="naam">
					<Input placeholder="Naam" />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" icon="search" />
				</Form.Item>
			</Form>
			<List
				grid={{
					gutter: 16,
					xs: 1,
					sm: 2,
					md: 3,
					lg: 3,
					xl: 4,
					xxl: 4
				}}
				dataSource={filteredRassen}
				renderItem={item => (
					<List.Item>
						<Card actions={[<Kleuren ras={item} />, <RasSwitch key={item.id} ras={item} onChange={toggleRas} />]}>
							<Card.Meta title={item.naam} />
						</Card>
					</List.Item>
				)}
			/>
		</Fragment>
	);
};


import { Alert, Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { has, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { memberActions } from '../../store/member';


export const Fokker: React.FC<{ mid: number }> = (props) => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState<'none' | 'saving' | 'saved' | 'error'>('none');
	const [statusMessage, setStatusMessage] = useState<React.ReactNode>(null);

	const { breederLoaded, breeder, editAllowed } = useSelector((store: StoreState) => ({
		breederLoaded: has(store.member.breeders, props.mid),
		breeder: store.member.breeders[props.mid],
		editAllowed: store.userData.profile.hasAnyRole(['administrator', 'ledenbeheer beheren'])
	}));

	useEffect(() => {
		dispatch(memberActions.getBreeder(props.mid));
	}, []);

	useEffect(() => {
		switch (status) {
			case 'error':
				setStatusMessage(<Alert message="Fout bij het opslaan van de wijzigingen" type="error" />);
				break;
			case "saved":
				setStatusMessage(<Alert message="De wijzigingen werden opgeslaan" type="success" />);
				break;
			case "saving":
				setStatusMessage(<Alert message="De wijzigingen worden opgeslaan" type="info" />);
				break;
			default:
				if (!registered) {
					setStatusMessage(<Alert type="info" message="Deze persoon is nog niet gekend als fokker." />);
				}
				else {
					setStatusMessage(null);
				}
				break;
		}
	}, [status]);

	if (!breederLoaded) {
		return <div>Loading...</div>
	}

	const registered = !isEmpty(breeder);

	let actions: React.ReactNode = null;
	if (editAllowed) {
		actions = (<div><Button type="primary" htmlType="submit">Opslaan</Button></div>);
	}

	// updateBreeder: (mid: number, breeder: any) => {
	// 	return dispatch(memberActions.updateBreeder(mid, breeder));
	// }

	const onFinish = (values: any) => {
		setStatus("saving");
		let breeder_data: any = {};
		if (values.sanitel) {
			breeder_data.sanitel = values.sanitel;
		}

		if (values.stamboekcode && values.stamboekcode > 0) {
			breeder_data.stamboekcode = values.stamboekcode;
		}

		if (values.fokkernummer) {
			breeder_data.fokkernummer = values.fokkernummer;
		}

		if (values.vbn && values.vbn > 0) {
			breeder_data.vbn = values.vbn;
		}

		if (values.dieren) {
			breeder_data.dieren = values.dieren;
		}

		dispatch(memberActions.updateBreeder(props.mid, breeder_data))
			.then(_ => {
				setStatus("saved");
				dispatch(memberActions.getBreeder(props.mid));
			})
			.catch(_ => {
				setStatus("error");
			});
	}

	return (
		<div>
			{statusMessage}
			<Form initialValues={breeder} onFinish={onFinish}>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item<any> label="Sanitel" name="sanitel" rules={[{ required: false }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item<any> label="Stamboekcode" name="stamboekcode" rules={[{ required: false }]}>
							<InputNumber />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item<any> label="Fokkernummer" name="fokkernummer" rules={[{ required: false }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item<any> label="VBN id" name="vbn" rules={[{ required: false }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col>
						<Form.Item<any> label="Dieren" name="dieren" rules={[{ required: false }]}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col>
						{actions}
					</Col>
				</Row>
			</Form>
		</div>
	);
};

import { Layout, Menu } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { StoreState } from "../store";
import { userManager } from "../userManager";
import { Members } from "./members/Index";
import { My } from "./my/Index";
import { Qr } from "./qr";
import { VereenvoudigdBeheer } from "./vereenvoudigd-beheer";

interface AppProps extends RouteComponentProps<{}> { }

export const App = (props: AppProps) => {

    useEffect(
        () => {
            const { history } = props;
            const location = history.location;
            userManager.signinSilent().then(() => {
                const goto = location ? (location.pathname + location.search + location.hash) : "/mijn";
                history.push(goto);
            });
        },
        [false]
    );

    const { auth, profile } = useSelector((state: StoreState) => ({
        auth: state.auth,
        profile: state.userData.profile
    }));

    function logout() {
        const { history } = props;
        userManager.removeUser()
            .then(_ => {
                history.push("/");
            });
    }

    const appComponents: React.ReactNode[] = [];
    const routes: React.ReactNode[] = [];

    if (auth.user) {
        appComponents.push(<Menu.Item key="mijn"><NavLink to="/mijn">Mijn gegevens</NavLink></Menu.Item>);
        routes.push(<Route key="mijn" path="/mijn" render={props => <My basePath="/mijn" {...props} />} />);
        routes.push(<Route key="mijn2" path="/" exact render={() => <Redirect to="/mijn" />} />);

        if (profile.hasAnyRole(["administrator", "ledenbeheer lezen"])) {
            appComponents.push(<Menu.Item key="ledenbeheer"><NavLink to="/ledenbeheer">Ledenbeheer</NavLink></Menu.Item>);
            routes.push(
                <Route key="ledenbeheer" path="/ledenbeheer" render={props => <Members basePath="/ledenbeheer" {...props} />} />
            );
        }

        if (profile.hasAnyRole(["administrator", "vereenvoudigd beheer lezen"])) {
            appComponents.push(<Menu.Item key="vereenvoudigd-beheer"><NavLink to="/vereenvoudigd-beheer">Vereenvoudigd beheer</NavLink></Menu.Item>);
            routes.push(
                <Route key="vereenvoudigd-beheer" path="/vereenvoudigd-beheer" render={props => <VereenvoudigdBeheer basePath="/vereenvoudigd-beheer" {...props} />} />
            );
        }

        if (profile.hasAnyRole(["administrator", "beheer qr-codes"])) {
            appComponents.push(<Menu.Item key="qr"><NavLink to="/qr">QR codes</NavLink></Menu.Item>);
            routes.push(<Route key="qr" path="/qr" render={props => <Qr basePath="/qr" {...props} />} />);
        }

        appComponents.push(<Menu.Item key="4"><NavLink to="/logout" onClick={logout}>Afmelden</NavLink></Menu.Item>);
    } else {
        appComponents.push(<Menu.Item key="5"><NavLink to="/login">Aanmelden</NavLink></Menu.Item>);
    }

    return (
        <Layout>
            <Layout.Header className="header">
                <div style={{ float: "left", width: "150px" }}>
                    <h1 style={{ color: "#ffffff" }}>mijnSLE</h1>
                </div>
                <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }}>
                    {appComponents}
                </Menu>
            </Layout.Header>
            <Switch>
                {routes}
            </Switch>
            <Layout.Footer>
                <div style={{ textAlign: "center" }}>mijn.sle.be &copy; 2018 - SLE vzw</div>
            </Layout.Footer>
        </Layout>
    );

};

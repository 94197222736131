import { Button, Spin, notification } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { StoreState } from "../../../store";
import { mijnActions } from "../../../store/mijn";
import { Intro } from "./Intro";
import { Invoer } from "./Invoer";
import { Weergave } from "./Weergave";
import { getAsyncUiState } from "../../../store/ui";

interface VereenvoudigdBeheerProps {
	basePath: string;
}

export const VereenvoudigdBeheer = (props: VereenvoudigdBeheerProps) => {

	const dispatch = useDispatch();
	const [memberAsync, resetMemberAsync] = getAsyncUiState(mijnActions.getContactGegevens.toString());
	const [vbAsync, resetVbAsync] = getAsyncUiState("mijn/vereenvoudigd-beheer/get");

	useEffect(
		() => {
			dispatch(mijnActions.getContactGegevens());
			dispatch(mijnActions.getDataVereenvoudigdBeheer("mijn/vereenvoudigd-beheer/get"));
		},
		[false]
	);

	const { member, data } = useSelector((state: StoreState) => ({
		member: state.mijn.member,
		data: state.mijn.vereenvoudigdBeheer
	}));

	const [loading, setLoading] = useState(true);

	useEffect(
		() => {
			if (memberAsync.hasCompleted && vbAsync.hasCompleted) {
				if (memberAsync.error) {
					notification.error({ key: "member", message: "Oeps...", description: "Er is een fout opgetreden bij het laden van uw gegevens, probeer nog eens of contacteer SLE (info@sle.be) " + memberAsync.error.toString() })
				} else if (vbAsync.error) {
					notification.error({ key: "member", message: "Oeps...", description: "Er is een fout opgetreden bij het laden van uw gegevens, probeer nog eens of contacteer SLE (info@sle.be) " + vbAsync.error.toString() })
				} else {
					setLoading(false);
					resetMemberAsync();
					resetVbAsync();
				}
			}
		},
		[memberAsync.isRunning, vbAsync.isRunning]
	);

	let content: React.ReactNode = null;
	if (loading) {
		content = (
			<Spin />
		);
	} else if (!member || isEmpty(member)) {
		content = <Fragment>
			<p>Om deel te nemen aan het vereenvoudigd beheer hebben we eerst uw contactgegevens nodig.</p>
			<NavLink to={props.basePath + "/contact"}>
				<Button type="primary">Contactgegevens invullen</Button>
			</NavLink>
		</Fragment>;
	} else if (!data || !data.deelnemer) {
		content = <Intro />;
	} else {
		content = <div>
			<div style={{ marginBottom: "16px" }}>
				<Invoer />
			</div>
			<Weergave />
		</div>;
	}

	return (
		<Fragment>
			<div style={{ marginBottom: "16px" }}><h2>Vereenvoudigd beheer</h2></div>
			{content}
		</Fragment>
	);

};

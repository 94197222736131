import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import download from "js-file-download";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { StoreState } from "../../store";
import { memberActions } from "../../store/member";
import { getAsyncUiState } from "../../store/ui";

const columns: ColumnsType<any> = [
	{
		title: "Titel",
		dataIndex: "title"
	},
	{
		title: "Omschrijving",
		dataIndex: "description"
	},
	{
		title: "",
		dataIndex: "report",
		render: (text: any, record: any, index: any): React.ReactNode => {
			return (<Button shape="circle" icon={<DownloadOutlined />} onClick={record.func} />);
		}
	}
];

export const Reports = () => {
	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const [asyncVerzendlijstState, resetAsyncVerzendlijstState] = getAsyncUiState(memberActions.downloadReportVerzendlijst.toString());
	const [asyncBetalingsuitnodingState, resetAsyncBetalingsuitnodigingState] = getAsyncUiState(memberActions.downloadReportBetalingsUitnodiging.toString());
	const [asyncMembersVsWebusersState, resetAsyncMembersVsWebusersState] = getAsyncUiState(memberActions.downloadMembersVsWebUsers.toString());
	const [asyncMailingListState, resetAsyncMailingListState] = getAsyncUiState(memberActions.downloadMailingLists.toString());

	useEffect(
		() => {
			if (asyncVerzendlijstState.isRunning) {
				notification.info({ key: "verzendlijst", message: "Even geduld", description: "Het rapport wordt aangemaakt" });
			}

			if (asyncVerzendlijstState.hasCompleted) {
				if (asyncVerzendlijstState.error) {
					notification.error({ key: "verzendlijst", message: "Oeps...", description: "Fout: " + asyncVerzendlijstState.error.toString() });
				} else {
					notification.close("verzendlijst");
					download(
						asyncVerzendlijstState.payload.data,
						asyncVerzendlijstState.payload.filename,
						asyncVerzendlijstState.payload.mime
					);
				}

				resetAsyncVerzendlijstState();
			}
		},
		[asyncVerzendlijstState]
	);

	useEffect(
		() => {
			if (asyncBetalingsuitnodingState.isRunning) {
				notification.info({ key: "betaallijst", message: "Even geduld", description: "Het rapport wordt aangemaakt" });
			}

			if (asyncBetalingsuitnodingState.hasCompleted) {
				if (asyncBetalingsuitnodingState.error) {
					notification.error({ key: "betaallijst", message: "Oeps...", description: "Fout: " + asyncBetalingsuitnodingState.error.toString() });
				} else {
					notification.close("betaallijst");
					download(
						asyncBetalingsuitnodingState.payload.data,
						asyncBetalingsuitnodingState.payload.filename,
						asyncBetalingsuitnodingState.payload.mime
					);
				}

				resetAsyncBetalingsuitnodigingState();
			}
		},
		[asyncBetalingsuitnodingState]
	);

	useEffect(
		() => {
			if (asyncMembersVsWebusersState.isRunning) {
				notification.info({ key: "membersvswebusers", message: "Even geduld", description: "Het rapport wordt aangemaakt" });
			}

			if (asyncMembersVsWebusersState.hasCompleted) {
				if (asyncMembersVsWebusersState.error) {
					notification.error({ key: "membersvswebusers", message: "Oeps...", description: "Fout: " + asyncMembersVsWebusersState.error.toString() });
				} else {
					notification.close("membersvswebusers");
					download(
						asyncMembersVsWebusersState.payload.data,
						asyncMembersVsWebusersState.payload.filename,
						asyncMembersVsWebusersState.payload.mime
					);
				}

				resetAsyncMembersVsWebusersState();
			}
		},
		[asyncMembersVsWebusersState]
	);

	useEffect(
		() => {
			if (asyncMailingListState.isRunning) {
				notification.info({ key: "mailinglists", message: "Even geduld", description: "Het rapport wordt aangemaakt" });
			}

			if (asyncMailingListState.hasCompleted) {
				if (asyncMailingListState.error) {
					notification.error({ key: "mailinglists", message: "Oeps...", description: "Fout: " + asyncMailingListState.error.toString() });
				} else {
					notification.close("mailinglists");
					download(
						asyncMailingListState.payload.data,
						asyncMailingListState.payload.filename,
						asyncMailingListState.payload.mime
					);
				}

				resetAsyncMailingListState();
			}
		},
		[asyncMailingListState]
	);

	const data: any[] = [
		{
			title: "Verzendlijst De Ark",
			description: "Adreslijst voor alle actieve lidmaatschappen",
			report: "verzendlijst",
			func: () => dispatch(memberActions.downloadReportVerzendlijst())
		},
		{
			title: "Betalingsuitnodiging lidmaatschap",
			description: "Adreslijst voor het versturen van de betalingsuitnodiging voor vervallen lidmaatschappen",
			report: "betalingsuitnodiging",
			func: () => setModalVisible(true)
		},
		{
			title: "Vergelijken leden en web-gebruikers",
			description: "Dit rapport vergelijkt alle leden-gegevens met de gebruikers profielen op de website",
			report: "membersvswebusers",
			func: () => dispatch(memberActions.downloadMembersVsWebUsers())
		},
		{
			title: "Mailing Lists (import voor Odoo)",
			description: "Import-bestand voor mailing-lijsten in Odoo",
			report: "mailinglists",
			func: () => dispatch(memberActions.downloadMailingLists())
		}
	];

	function downloadBetalingsUitnodiging() {
		form
			.validateFields()
			.then(values => {
				setModalVisible(false);
				dispatch(memberActions.downloadReportBetalingsUitnodiging(values.kwartaal));
			})
			.catch(err => {
				notification.error({ key: "betalingsuitnodiging", message: err.toString() });
			});
	}

	return (
		<Fragment>
			<h2>Rapporten</h2>
			<Table columns={columns} dataSource={data} rowKey="title" pagination={false} />
			<Modal
				title="Adreslijst betalingsuitnodiging"
				visible={modalVisible}
				onCancel={() => setModalVisible(false)}
				onOk={() => downloadBetalingsUitnodiging()}
			>
				<Form layout="vertical" form={form}>
					<Form.Item label="Kwartaal" name="kwartaal" rules={[{ required: true, message: "Geef een kwartaal in" }]}>
						<Input placeholder="2019-2" />
					</Form.Item>
				</Form>
			</Modal>
		</Fragment>
	);
}

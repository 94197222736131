import { produce } from "immer";
import { forEach, NumericDictionary, remove } from "lodash";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ERROR_ACTION, WAIT_FOR_ACTION } from "redux-wait-for-action";
import { StoreState } from ".";
import { Report } from "../api/reports";
import { find, getAlleDataRapport, getOverview, getOverviewDataRapport, listKleuren, update } from "../api/vereenvoudigdBeheer";

export interface Ras {
	id: number;
	naam: string;
	actief: boolean;
}

export interface Kleur {
	id: number;
	rid: number;
	kleur: string;
}

export interface VereenvoudigdBeheerState {
	rassen: Ras[];
	kleuren: NumericDictionary<{ id: number; kleur: string; }[]>;
	overview: Array<{naam: string, aantal: number}>;
}

const initialState: VereenvoudigdBeheerState = {
	rassen: [],
	kleuren: {},
	overview: []
};

export const vereenvoudigdBeheerActions = {
	loadOverview: (): AnyAction => {
		return {
			type: "VEREENVOUDIGD_BEHEER_OVERVIEW_LOAD"
		}
	},
	loadRassen: (): AnyAction => {
		return {
			type: "VEREENVOUDIGD_BEHEER_RASSEN_LOAD"
		};
	},
	updateRas: (ras: Ras): AnyAction => {
		return {
			type: "VEREENVOUDIGD_BEHEER_RAS_UPDATE",
			[WAIT_FOR_ACTION]: "VEREENVOUDIGD_BEHEER_RAS_UPDATED",
			[ERROR_ACTION]: "VEREENVOUDIGD_BEHEER_RAS_UPDATE_ERROR",
			payload: ras
		};
	},
	loadKleuren: (): AnyAction => {
		return {
			type: "VEREENVOUDIGD_BEHEER_KLEUREN_LOAD"
		}
	},
	downloadAlleDataRapport: (): AnyAction => {
		return {
			type: "VEREENVOUDIGD_BEHEER_ALLE_DATA_RAPPORT",
			[WAIT_FOR_ACTION]: "VEREENVOUDIGD_BEHEER_ALLE_DATA_RAPPORT_OK",
			[ERROR_ACTION]: "VEREENVOUDIGD_BEHEER_ALLE_DATA_RAPPORT_ERROR",
			payload: {}
		}
	},
	downloadOverviewDataRapport: (): AnyAction => {
		return {
			type: "VEREENVOUDIGD_BEHEER_OVERVIEW_RAPPORT",
			[WAIT_FOR_ACTION]: "VEREENVOUDIGD_BEHEER_OVERVIEW_RAPPORT_OK",
			[ERROR_ACTION]: "VEREENVOUDIGD_BEHEER_OVERVIEW_RAPPORT_ERROR",
			payload: {}
		}
	}
};

export function vereenvoudigdBeheerReducer(state: VereenvoudigdBeheerState = initialState, action: AnyAction): VereenvoudigdBeheerState {
	if (!action) {
		return produce(state, draft => { });
	}

	switch (action.type) {
		case "VEREENVOUDIGD_BEHEER_OVERVIEW_LOADED":
			console.log(action.payload);
			return produce(state, draft => {
				draft.overview = action.payload
			});
		case "VEREENVOUDIGD_BEHEER_RASSEN_LOADED":
			return produce(state, draft => {
				draft.rassen = action.payload
			});
		case "VEREENVOUDIGD_BEHEER_RAS_UPDATED":
			return produce(state, draft => {
				remove(draft.rassen, ras => ras.id === action.payload.id);
				draft.rassen.push(action.payload);
			});
		case "VEREENVOUDIGD_BEHEER_KLEUREN_LOADED":
			return produce(state, draft => {
				draft.kleuren = {};
				forEach(action.payload, kleur => {
					draft.kleuren[kleur.rid] = draft.kleuren[kleur.rid] || [];
					draft.kleuren[kleur.rid].push({ id: kleur.id, kleur: kleur.kleur });
				});
			});
		default:
			return produce(state, draft => { });
	}
}

export function* vereenvoudigdBeheerSaga() {
	yield takeLatest("VEREENVOUDIGD_BEHEER_OVERVIEW_LOAD", loadOverviewSaga);
	yield takeLatest("VEREENVOUDIGD_BEHEER_RASSEN_LOAD", loadRassenSaga);
	yield takeLatest("VEREENVOUDIGD_BEHEER_RAS_UPDATE", updateRasSaga);
	yield takeLatest("VEREENVOUDIGD_BEHEER_KLEUREN_LOAD", loadKleurenSaga);
	yield takeLatest("VEREENVOUDIGD_BEHEER_ALLE_DATA_RAPPORT", downloadAlleDataRapportSaga);
	yield takeLatest("VEREENVOUDIGD_BEHEER_OVERVIEW_RAPPORT", downloadOverviewRapportSaga);
}

export function* loadOverviewSaga(action: AnyAction) {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(getOverview, accessToken);
		yield put({ type: "VEREENVOUDIGD_BEHEER_OVERVIEW_LOADED", payload: result });
	} catch (err) {
		yield put({ type: "VEREENVOUDIGD_BEHEER_OVERVIEW_LOAD_ERROR", error: err });
	}
}

export function* loadRassenSaga(action: AnyAction) {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(find, accessToken);
		yield put({ type: "VEREENVOUDIGD_BEHEER_RASSEN_LOADED", payload: result });
	} catch (err) {
		yield put({ type: "VEREENVOUDIGD_BEHEER_RASSEN_LOAD_ERROR", error: err });
	}
}

export function* updateRasSaga(action: AnyAction) {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(update, accessToken, action.payload);
		yield put({ type: "VEREENVOUDIGD_BEHEER_RAS_UPDATED", payload: action.payload });
	} catch (err) {
		yield put({ type: "VEREENVOUDIGD_BEHEER_RAS_UPDATE_ERROR", error: err });
	}
}

export function* loadKleurenSaga(action: AnyAction) {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const result = yield call(listKleuren, accessToken);
		yield put({ type: "VEREENVOUDIGD_BEHEER_KLEUREN_LOADED", payload: result });
	} catch (err) {
		yield put({ type: "VEREENVOUDIGD_BEHEER_KLEUREN_LOAD_ERROR", error: err });
	}
}

export function* downloadAlleDataRapportSaga() {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const report: Report = yield call(getAlleDataRapport, accessToken);
		yield put({ type: "VEREENVOUDIGD_BEHEER_ALLE_DATA_RAPPORT_OK", payload: report });
	} catch (err) {
		yield put({ type: "VEREENVOUDIGD_BEHEER_ALLE_DATA_RAPPORT_ERROR", payload: err });
	}
}

export function* downloadOverviewRapportSaga() {
	try {
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const report: Report = yield call(getOverviewDataRapport, accessToken);
		yield put({ type: "VEREENVOUDIGD_BEHEER_OVERVIEW_RAPPORT_OK", payload: report });
	} catch (err) {
		yield put({ type: "VEREENVOUDIGD_BEHEER_OVERVIEW_RAPPORT_ERROR", payload: err });
	}
}

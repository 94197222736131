
import { Button, Card, Col, Form, InputNumber, notification, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { mijnActions } from "../../store/mijn";
import { getAsyncUiState } from "../../store/ui";

export const LidgeldBetalen = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [beschermendLidCurrentValue, setBeschermendLidCurrentValue] = useState<number>(50.0);

	const [asyncBetalingState, resetAsyncBetalingState] = getAsyncUiState(mijnActions.initieerBetalingLidgeld.toString());

	useEffect(
		() => {
			if (asyncBetalingState.isRunning) {
				notification.info({ message: "Even geduld", description: "U wordt zo dadelijk omgeleid naar de website van onze partner waar we uw betaling verder afhandelen." });
			}

			if (asyncBetalingState.hasCompleted) {
				if (asyncBetalingState.error) {
					notification.error({ message: "Fout", description: "Er is een fout opgetreden bij het aanmaken van uw betaling. Probeer het nog eens of neem contact op met SLE." });
				} else {
					if (!asyncBetalingState.payload && !asyncBetalingState.payload.redirectUri) {
						notification.error({ message: "Fout", description: "Er is een fout opgetreden bij het aanmaken van uw betaling. Probeer het nog eens of neem contact op met SLE." });
					} else {
						window.location.href = asyncBetalingState.payload.redirectUri;
					}
				}

				resetAsyncBetalingState();
			}
		},
		[asyncBetalingState]
	);

	useEffect(
		() => {
			dispatch(mijnActions.getContactGegevens());
			dispatch(mijnActions.getLidmaatschap());
		},
		[false]
	);

	const { member, subscription } = useSelector((state: StoreState) => ({
		member: state.mijn.member || {},
		subscription: state.mijn.subscription
	}));

	useEffect(
		() => {
			setLoading(loading ? false : true);
		},
		[subscription]
	);

	if (loading) {
		return (
			<Spin />
		);
	}

	function maakBetaling(info: { bedrag: number }) {
		dispatch(mijnActions.initieerBetalingLidgeld(info.bedrag));
	}

	function numberToEuro(val: number): string {
		return "€ " + val.toLocaleString("nl-BE", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
	}

	const land: string = (member.land || "België").toString();
	const gewoonLidBedrag: number = land === "België" ? 25.00 : 40.00;

	let message: React.ReactNode;
	if (isEmpty(subscription)) {
		message = (<p>De laatste stap om lid te worden van SLE is de betaling van het lidgeld. Maak hieronder uw keuze.</p>);
	} else if (subscription.status === "te-vernieuwen") {
		message = (<p>Uw was weer een jaar lid van SLE, bedankt daarvoor. Via onderstaande pagina kan u uw lidmaatschap vernieuwen.</p>);
	} else {
		message = (<p>Uw lidmaatschap is nog actief of reeds geschrapt. Neem contact op met Steunpunt Levend Erfgoed indien u vragen heeft over uw lidmaatschap.</p>);

		return (
			<div>
				<h2>Betaling lidgeld</h2>
				{message}
			</div>
		);
	}

	return (
		<Fragment>
			<h2>Betaling lidgeld</h2>
			{message}
			<Row gutter={16}>
				<Col span={12}>
					<Card title="Gewoon lid" actions={[
						<Button type="primary" htmlType="button" onClick={() => maakBetaling({ bedrag: gewoonLidBedrag })}>OK</Button>
					]}>
						<p>Als gewoon lid van Steunpunt Levend Erfgoed vzw:</p>
						<ul>
							<li>draagt u daadwerkelijk een steentje bij tot de instandhouding van de streekeigen rassen van Belgische landbouw- en neerhofdieren</li>
							<li>ontvangt u vier maal per jaar het ledentijdschrift (64 blz. A5), De ARK, met daarin heel wat informatie
                                    over onze oude rassen, over het kweken van dieren, over de reglementering rond het houden van dieren</li>
							<li>kan u gratis kleine aankondigingen plaatsen in De Ark m.b.t. fokdieren</li>
							<li>kan u gratis zoekertjes plaatsen op deze webstek</li>
							<li>kan u deelnemen aan de stamboekwerking rond schapen en geiten</li>
							<li>kan u deelnemen aan het eenvoudig of uitgebreid beheer van neerhofdieren</li>
						</ul>
						<p>Als gewoon lid in {land} betaalt u {numberToEuro(gewoonLidBedrag)} per jaar.</p>
					</Card>
				</Col>
				<Col span={12}>
					<Card title="Beschermend lid" actions={[
						<Button type="primary" htmlType="button" onClick={() => maakBetaling({ bedrag: beschermendLidCurrentValue })}
							disabled={50 - beschermendLidCurrentValue > 0.001}>OK</Button>
					]}>
						<p>Als beschermend lid van Steunpunt Levend Erfgoed vzw:</p>
						<ul>
							<li>geniet u alle voordelen van een gewoon lid</li>
							<li>draagt u financieel extra bij tot het behoud van ons levend erfgoed</li>
							<li>wordt uw naam opgenomen in de lijst van beschermende leden die &eacute;&eacute;nmaal per jaar in De Ark verschijnt</li>
						</ul>
						<p>Als beschermend lid betaalt u {numberToEuro(50.00)} of meer per jaar.</p>
						<Form form={form} layout="inline" initialValues={{ bedrag: 50 }}>
							<Form.Item
								label="Hoeveel wenst u bij te dragen"
								name="bedrag"
								rules={[{ required: true, message: "Gelieve een bedrag in te geven" }]}
							>
								<InputNumber min={50} onChange={val => setBeschermendLidCurrentValue(val || 50)} />
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
}

import { Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { vereenvoudigdBeheerActions } from "../../store/vereenvoudigdBeheer";
import { RassenLijst } from "./RassenLijst";

const { TabPane } = Tabs;

interface RassenProps {
    loadRassen: () => void;
    loadKleuren: () => void;
}

class RassenComponent extends React.Component<RassenProps> {
    componentDidMount() {
        this.props.loadRassen();
        this.props.loadKleuren();
    }

    render() {
        return (
            <div>
                <h2>Vereenvoudigd beheer</h2>
                <Tabs defaultActiveKey="actief" destroyInactiveTabPane={false}>
                    <TabPane tab="Deelnemende rassen" key="actief">
                        <RassenLijst type="actieve" />
                    </TabPane>
                    <TabPane tab="Niet-deelnemende rassen" key="inactief">
                        <RassenLijst type="inactieve" />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export function mapDispatchToProps(dispatch: Dispatch): RassenProps {
    return {
        loadRassen: () => {
            dispatch(vereenvoudigdBeheerActions.loadRassen());
        },
        loadKleuren: () => {
            dispatch(vereenvoudigdBeheerActions.loadKleuren());
        }
    };
}

export const Rassen = connect(undefined, mapDispatchToProps)(RassenComponent);

import { produce } from "immer";
import { AnyAction } from "redux";
import { createAction, ActionFunction1, Action } from "redux-actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { StoreState } from ".";
import { createQrCode, deleteQrCode, downloadQrCodeImage, getQrCodes, updateQrCode } from "../api/qr";
import { completeAsyncUiAction, startAsyncUiAction } from "./ui";
import { QrCodeModal } from "../components/qr/QrCodeModal";

export interface QrCode {
	id: number;
	name: string;
	link: string;
	enabled: boolean;
}

const emptyQrCode: QrCode = {
	id: 0,
	name: "",
	link: "",
	enabled: false
};

const noop: ActionFunction1<QrCode, Action<QrCode>> = (t1: QrCode) => {
	return {
		type: "noop",
		payload: emptyQrCode
	};
};

export interface QrCodesState {
	codes: QrCode[];
	modal: {
		visible: boolean;
		title: string;
		qrCode: QrCode;
		actionCreator: ActionFunction1<QrCode, Action<QrCode>>
	};
}

const initialState: QrCodesState = {
	codes: [],
	modal: {
		visible: false,
		title: "",
		qrCode: emptyQrCode,
		actionCreator: noop
	}
};

export const qrActions = {
	load: createAction("qr/load"),
	loaded: createAction("qr/load/ok"),
	create: createAction<QrCode>("qr/create"),
	update: createAction<QrCode>("qr/update"),
	delete: createAction<number>("qr/delete"),
	download: createAction("qr/download", (type: string, code: QrCode) => ({ type, code })),
	openCreateModal: createAction("qr/modal/open/create"),
	openEditModal: createAction<QrCode>("qr/modal/open/edit"),
	closeModal: createAction("qr/modal/close")
};

export function qrCodesReducer(state: QrCodesState = initialState, action: AnyAction): QrCodesState {
	if (!action) {
		return produce(state, draft => { });
	}

	switch (action.type) {
		case qrActions.loaded.toString():
			return produce(state, draft => { draft.codes = action.payload; });
		case qrActions.openCreateModal.toString():
			return produce(state, draft => {
				draft.modal = {
					visible: true,
					title: "QR code toevoegen",
					qrCode: emptyQrCode,
					actionCreator: qrActions.create
				};
			});
		case qrActions.openEditModal.toString():
			return produce(state, draft => {
				draft.modal = {
					visible: true,
					title: "QR code bewerken",
					qrCode: action.payload,
					actionCreator: qrActions.update
				};
			});
		case qrActions.closeModal.toString():
			return produce(state, draft => {
				draft.modal = initialState.modal;
			});
		default:
			return produce(state, draft => { });
	}
}

export function* qrCodesSaga() {
	yield takeLatest(qrActions.load.toString(), qrCodesLoadSaga);
	yield takeLatest(qrActions.create.toString(), qrCodeCreateSaga);
	yield takeLatest(qrActions.update.toString(), qrCodeUpdateSaga);
	yield takeLatest(qrActions.delete.toString(), qrCodeDeleteSaga);
	yield takeLatest(qrActions.download.toString(), qrCodeDownloadSaga);
}

export function* qrCodesLoadSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const codes = yield call(getQrCodes, accessToken);
		yield put(qrActions.loaded(codes));
		yield completeAsyncUiAction(action.type);
	} catch (err) {
		yield (completeAsyncUiAction(action.type, err));
	}
}

export function* qrCodeCreateSaga(action: AnyAction): any {

	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(createQrCode, accessToken, action.payload);
		yield completeAsyncUiAction(action.type);
		yield put(qrActions.load());
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

export function* qrCodeUpdateSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(updateQrCode, accessToken, action.payload);
		yield completeAsyncUiAction(action.type);
		yield put(qrActions.load());
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

export function* qrCodeDeleteSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		yield call(deleteQrCode, accessToken, action.payload);
		yield completeAsyncUiAction(action.type);
		yield put(qrActions.load());
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}

export function* qrCodeDownloadSaga(action: AnyAction): any {
	try {
		yield startAsyncUiAction(action.type);
		const accessToken = yield select((state: StoreState) => state.auth.user ? state.auth.user.access_token : null);
		const response = yield call(downloadQrCodeImage, accessToken, action.payload.type, action.payload.code);
		yield completeAsyncUiAction(action.type, null, response);
	} catch (err) {
		yield completeAsyncUiAction(action.type, err);
	}
}
